import axios from 'axios';

export default {
  async getAllTodoItems() {
    const resp = await axios.get('/api/studio_todo/all');
    return resp.data;
  },
  async toggleCheckbox(data) {
    const resp = await axios.post('/api/studio_todo/toggle-checkbox', data);
    return resp.data;
  },
  async clearCheckbox(data) {
    const resp = await axios.post('/api/studio_todo/clear-checkbox', data);
    return resp.data;
  },
};

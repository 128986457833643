<template>
  <div>
    <ui-transition>
      <article v-if="!isLoading">
        <section class="bg-level-1 shadow-xl rounded-xl px-4 py-3 mb-4">
          <p class="font-bold text-sm text-main mb-2">
            {{
              selectedGroupId ? todo.find(item => item.id === selectedGroupId).name : '全て表示中'
            }}
          </p>
          <div :class="`grid grid-cols-7 mb-2`">
            <button
              v-for="(collection, i) in todo"
              :key="collection.id"
              class="focus:outline-none first:rounded-l-xl last:rounded-r-xl text-sm py-2"
              :class="
                selectedGroupId === collection.id
                  ? 'text-main bg-indigo-600 shadow-xl'
                  : 'text-sub bg-level-2'
              "
              @click="selectedGroupId = collection.id === selectedGroupId ? 0 : collection.id"
            >
              {{ i + 1 }}
            </button>
          </div>
          <ui-progress :value="checked" :max="all" />
          <div class="flex items-center justify-between mt-4">
            <div class="text-sub text-xs">
              未完了のタスクのみ表示
            </div>
            <ui-switch v-model="showOnlyIncompletedTasks" />
          </div>
          <ui-button class="text-xs w-full mt-4" color="error" @click="clearConfirmDialog = true">
            全てクリア
          </ui-button>
        </section>
        <div v-for="collection in filteredTodo" :key="collection.id">
          <section v-for="list in collection.studio_todo_lists" :key="list.id">
            <transition
              enter-active-class="ease-out transform duration-100"
              enter-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="ease-in transform duration-100"
              leave-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-if="
                  list.studio_todo_items.length &&
                    (!showOnlyIncompletedTasks ||
                      list.studio_todo_items.some(item => !item.checked))
                "
                class="mb-6 px-1"
              >
                <h2
                  class="mb-3 border-b border-gray-600 py-2 border-opacity-50 flex items-center justify-between"
                >
                  <div class="flex-grow">
                    <p class="text-sm font-bold text-main">
                      {{ list.name || list.equipment.name }}
                    </p>
                    <p v-if="list.equipment" class="text-xxs text-sub">
                      {{ list.equipment.equipment_place.name }}
                    </p>
                  </div>
                  <img
                    v-if="list.equipment && list.equipment.thumbnail"
                    v-lazy="`/uploads/equipments/${list.equipment.thumbnail}`"
                    class="w-10 h-10 rounded-lg ml-1"
                  />
                </h2>
                <div v-for="item in list.studio_todo_items" :key="item.id">
                  <ui-checkbox
                    v-model="item.checked"
                    class="text-sm mb-1"
                    :label="item.name"
                    @change="toggleCheckbox(collection.id, list.id, item.id, $event)"
                  />
                  <a
                    v-if="item.attached_image"
                    class="mobx"
                    :href="`/uploads/studio_todo/${item.attached_image}`"
                    :data-title="item.name"
                  >
                    <img
                      v-lazy="`/uploads/studio_todo/${item.attached_image}`"
                      class="rounded-xl shadow-xl mb-4 mx-auto w-10/12"
                    />
                  </a>
                </div>
              </div>
            </transition>
          </section>
        </div>
      </article>
    </ui-transition>
    <ui-dialog
      v-model="clearConfirmDialog"
      title="確認"
      type="error"
      description="全てのチェックボックスを未チェック状態にします。よろしいですか？"
    >
      <ui-button color="error" class="mb-2" @click="clearCheckboxes">
        全てクリア
      </ui-button>
      <ui-button @click="clearConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import StudioTodoService from '../services/StudioTodoService'

export default {
  name: 'StudioTodo',
  data: () => ({
    isLoading: true,
    todo: [],
    ws: null,
    wsHealthCheckTimer: null,
    wsReconnectTimer: null,
    clearConfirmDialog: false,
  }),
  computed: {
    selectedGroupId: {
      get() {
        return this.$store.getters['app/studioTodoSelectedGroupId']
      },
      set(value) {
        this.$store.commit('app/studioTodoSelectedGroupId', value)
      },
    },
    showOnlyIncompletedTasks: {
      get() {
        return this.$store.getters['app/studioTodoShowOnlyIncompletedTasks']
      },
      set(value) {
        this.$store.commit('app/studioTodoShowOnlyIncompletedTasks', value)
      },
    },
    all() {
      return this.filteredTodo.reduce(
        (a, b) => a + b.studio_todo_lists.reduce((p, c) => p + c.studio_todo_items.length, 0),
        0,
      )
    },
    checked() {
      return this.filteredTodo.reduce(
        (a, b) =>
          a +
          b.studio_todo_lists.reduce(
            (p, c) => p + c.studio_todo_items.filter(item => item.checked).length,
            0,
          ),
        0,
      )
    },
    filteredTodo() {
      if (!this.selectedGroupId) {
        return this.todo
      }
      return this.todo.filter(collection => collection.id === this.selectedGroupId)
    },
  },
  async created() {
    this.subscribe()
    this.fetch()
  },
  mounted() {
    this.$nextTick(() => {
      this.$moduloBox.getGalleries()
    })
  },
  updated() {
    this.$nextTick(() => {
      this.$moduloBox.getGalleries()
    })
  },
  beforeDestroy() {
    this.stopWsReconnectTimer()
    this.stopWsHealthCheckTimer()
    this.ws.close()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.todo = await StudioTodoService.getAllTodoItems()
      const selectedGroup = this.todo.find(item => item.id === this.selectedGroupId)
      if (!selectedGroup) {
        this.selectedGroupId = 0
      }
      this.isLoading = false
    },
    async silentFetch() {
      this.todo = await StudioTodoService.getAllTodoItems()
    },
    async toggleCheckbox(collectionId, listId, itemId, checked) {
      await StudioTodoService.toggleCheckbox({
        deviceId: this.$deviceId,
        collectionId,
        listId,
        itemId,
        checked,
      })
    },
    async clearCheckboxes() {
      this.clearConfirmDialog = false
      await StudioTodoService.clearCheckbox({
        deviceId: this.$deviceId,
      })
      this.silentFetch()
    },
    subscribe() {
      return new Promise(resolve => {
        this.ws = new WebSocket('wss://fmapp.net/ws/connection')
        this.ws.onopen = () => resolve()
        this.ws.onmessage = event => {
          if (event.data === 'pong') {
            this.stopWsReconnectTimer()
            return
          }
          const data = JSON.parse(event.data)
          if (data.deviceId === this.$deviceId) return
          if (data.app !== 'studio_todo') return
          if (data.clear) {
            this.silentFetch()
            return
          }
          const targetCollection = this.todo.find(collection => collection.id === data.collectionId)
          if (!targetCollection) return
          const targetList = targetCollection.studio_todo_lists.find(
            list => list.id === data.listId,
          )
          if (!targetList) return
          const targetItem = targetList.studio_todo_items.find(item => item.id === data.itemId)
          if (!targetItem) return
          this.$set(targetItem, 'checked', data.checked)
        }

        this.wsHealthCheckTimer = setInterval(() => {
          this.ws.send('ping')
          this.startWsReconnectTimer()
        }, 10000)
      })
    },
    startWsReconnectTimer() {
      this.wsReconnectTimer = setTimeout(() => {
        this.stopWsHealthCheckTimer()
        this.ws.close()
        this.subscribe()
        this.silentFetch()
      }, 2000)
    },
    stopWsReconnectTimer() {
      clearTimeout(this.wsReconnectTimer)
    },
    stopWsHealthCheckTimer() {
      clearInterval(this.wsHealthCheckTimer)
    },
  },
}
</script>
